
import anime from 'animejs'

import resize from 'helpers/resize'
import math from 'helpers/math'
import scroll from 'core/scroll'

const ease = anime.penner.easeInQuad()

export default class Reveal {
  constructor (el, {
    revealZone = .1,
    className = 'revealed',
    shown = false
  } = {}) {
    this.el = el
    this.revealZone = revealZone
    // this.revealZone = el.getAttribute('data-zone') ? el.getAttribute('data-clamp').split(',').map(v => +v) : revealZone
    // const offset = (+el.getAttribute('data-offset')) || 0
    // this.revealZone[0] += offset
    // this.revealZone[1] += offset

    this.className = className
    this._shown = shown
    this.progress = 0
    this.visible = false
    this.onUpdate(0)
    // this.onDisappeared()

    if (shown) this.init()
  }

  init () {
    this._init = true
    setTimeout(this.resize.bind(this), 1)
    this.scroll = this.scroll.bind(this)
    scroll.instance().on(this.scroll)
  }

  show () {
    this.init()
  }

  shown () {
    this._shown = true
  }

  scrollTop () {
    const { scrollTop: _scrollTop } = scroll
    return !this._shown ? 0 : _scrollTop()
  }

  scroll () {
    if (!this._init) return

    const scrollTop = this.scrollTop()
    const offset = this.revealZone * resize.height()

    const top = this.bounds.top - scrollTop - resize.height()
    const bottom = this.bounds.bottom - scrollTop

    const height = Math.abs(this.bounds.bottom - this.bounds.top) + resize.height()
    const progress = math.clamp(-top / height, 0, 1)

    const appear = (resize.height() - (this.bounds.top - scrollTop) - offset) > 0
    const visible = top < 0 && bottom > 0
    // const belowZone = top < 0

    if (visible !== this.visible) {
      if (visible) this.onScreen()
      else this.outOfScreen()
    }

    this.onUpdate(progress)

    if (appear !== this.appear || visible !== this.visible) {
      if (!appear && !visible && this.visible) this.onDisappeared()
      if (appear && !this.appear) this.onAppear()
    }
    this.appear = appear
    this.visible = visible
  }

  // scroll () {
  //   if (!this._init) return
  //   const offset = this.revealZone[0] * resize.height()
  //   const limit = (this.revealZone[1] - this.revealZone[0]) * resize.height()
  //   const top = this.bounds.top - this.scrollTop()
  //   let progress = (resize.height() - top - offset) / limit
  //   progress = Math.min(1, Math.max(0, progress))

  //   const bottomVisible = this.bounds.bottom - this.scrollTop()
  //   const visible = progress > 0 && bottomVisible > 0

  //   if (visible !== this.visible) {
  //     if (visible) this.onScreen()
  //     else this.outOfScreen()
  //     this.visible = visible
  //   }

  //   if (progress !== this.progress) {
  // this.onUpdate(progress)

  //     if (this.progress === 0 || this.progress === 1) {
  //       this.onAnimationStart()
  //       if (this.progress === 0) this.onAppear()
  //       else if (this.progress === 1) this.onDisappear()
  //     } else if (progress === 1 || progress === 0) {
  //       this.onAnimationStop()
  //       if (progress === 1) this.onAppeared()
  //       else if (progress === 0) this.onDisappeared()
  //     }

  //     this.progress = progress
  //   }
  // }

  onUpdate () {

  }

  onAnimationStart () {
  }

  onAnimationStop () {
  }

  onAppeared () {
  }

  onAppear () {
    this.el.classList.add(this.className)
  }

  onDisappeared () {
    this.el.classList.remove(this.className)
  }

  onDisappear () {
  }

  onScreen () {
  }

  outOfScreen () {
  }

  ease (v) {
    return ease(v)
  }

  resize () {
    const { left, height, width } = this.el.getBoundingClientRect()

    let parent = this.el, top = 0

    do {
      top += parent.offsetTop
      parent = parent.offsetParent
    } while (parent)

    // if (~this.el.className.indexOf('article')) console.log(top, height, this.el)
    this.bounds = {
      bottom: top + height,
      top: top,
      left,
      height,
      width
    }

    setTimeout(this.scroll.bind(this), 1)
  }

  flush () {
    scroll.instance().off(this.scroll)
  }
}
