
import Reveal from '../reveal/Reveal'

export default class Progress extends Reveal {
  onUpdate (progress) {
    this.el.style.setProperty('--scroll-progress', progress)
  }

  onAppear () {
  }

  onDisappeared () {
  }
}
