import PageManager from 'navigation/SuperPageManager'
import scroll from 'core/scroll'
import resize from 'helpers/resize'
import SuperPage from 'navigation/SuperPage'
import Header from 'modules/header/Header'
import Progress from 'modules/library/progress/Progress'

import Projects from './projects/Projects'
import Home from './home/Home'
import About from './about/About'

class Main {
  constructor (el) {
    this.el = el
    this.createVirtualScroll()
    this.createPageManager()
    this.listenResize()

    scroll.init()
  }

  createPageManager () {
    this.pageManager = new PageManager(
      document.querySelector('.container'),
      '.page',
      [
        { route: 'realisations', pageClass: Projects },
        { route: 'secteurs/*', pageClass: Projects },
        { route: 'a-propos', pageClass: About },
        { route: 'contact', pageClass: SuperPage },
        { route: 'realisations/*', pageClass: SuperPage },
        { route: '*', pageClass: Home }
      ], {
        moduleMap: {
          header: ['.header', Header],
          progress: ['.scroll-progress', Progress]
          // '.reveal-text': TextReveal,
          // '.img-scroller': ImageScroller
        }
      })
    this.resize()
  }

  createVirtualScroll () {
    scroll.init()
  }

  listenResize () {
    resize.add(this)
    this.resize()
  }

  resize = () => {
    this.pageManager && this.pageManager.resize()
  };
}

export default Main
