import { reverse } from 'lodash'

import SuperPage from 'navigation/SuperPage'

export default class About extends SuperPage {
  constructor () {
    super(...arguments)

    this.toggleEvents()
  }

  createModules () {
    super.createModules(...arguments)
    this.modules.header.circles.push(...reverse(Array.from(this.el.querySelectorAll('.about__team > svg .circle'))))
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.metierButton.forEach(b => {
      b[method]('click', this.selectMetier)
    })
  }

  selectMetier = (e) => {
    const { currentTarget: target } = e
    const index = this.refs.metierButton.indexOf(target)

    this.refs.metierButton.forEach((button, i) => {
      button.classList.toggle('current', i === index)
      this.refs.metierDesc[i].classList.toggle('current', i === index)
    })
  }

  resize () {
    super.resize()
    this.refs.metierDescMobile.forEach((desc, i) => {
      desc.parentNode.style.setProperty('--desc-height', desc.offsetHeight + 'px')
    })
  }

  flush () {
    this.toggleEvents(false)
    super.flush()
  }
}
