import scroll from 'core/scroll'
import resize from 'helpers/resize'
import SuperPage from 'navigation/SuperPage'

export default class Home extends SuperPage {
  constructor () {
    super(...arguments)

    this.toggleEvents()
  }

  createModules () {
    super.createModules(...arguments)
    this.modules.header.circles.push(...Array.from(this.el.querySelectorAll('.home__intro .circle')))
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.enterButton[method]('click', this.enter)
  }

  enter = () => {
    scroll.scrollTo(resize.height())
  }

  flush () {
    this.toggleEvents(false)
    super.flush()
  }

  resize () {
    super.resize()
    this.refs.intro.style.height = `${window.innerHeight}px`
  }
}
