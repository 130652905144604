import { defer, each, map, invokeMap } from 'lodash-es'
import anime from 'animejs'

import promise from 'helpers/promise'
import scroll from 'core/scroll'

import router from '../core/router'

import Page from './Page'

const defaultModuleMap = {
}

export default class SuperPage extends Page {
  imagesToLoad = []
  colors = []

  constructor (el, options = {}) {
    super(...arguments)
    this.moduleMap = Object.assign({}, defaultModuleMap, options.moduleMap || {})
    this.inner = this.el.querySelector('.page__inner')
  }

  prepare (previousPage) {
    super.prepare(...arguments)
  }

  preload () {
    return Promise.resolve()
  }

  goToPreviousPage = (event) => {
    event.preventDefault()
    if (router.lastPage()) router.navigate(router.lastPage())
    else router.navigate(event.currentTarget.getAttribute('href'))
  }

  show (previousPage) {
    this.el.classList.add('top')
    this.el.classList.add('appearing')
    this.resize()

    // background.setupPage({
    //   opacity: this.getVideoOpacity(),
    //   blur: this.getVideoBlur()
    // })

    scroll.lock()
    return anime({
      targets: this.el,
      opacity: [0, 1],
      easing: 'easeOutQuad',
      duration: 600,
      changeBegin: () => {
        setTimeout(() => this.invokeShow(), 300)
      },
      delay: !previousPage ? 0 : 400,
      complete: () => {
        scroll.unlock()
        this.el.classList.remove('appearing')
        document.scrollingElement.scrollTop = 0
        defer(() => {
          this.resize()
        })
      }
    }).finished
  }

  hide (nextPage) {
    return anime({
      targets: this.el,
      opacity: [1, 0],
      easing: 'easeInQuad',
      duration: 400
    }).finished
  }

  invokeShow () {
    invokeMap(this.modulesArray, 'show')
  }

  askShow = previousPage => {
    return this.preloadImages()
      .then(() => this.preload(previousPage))
      .then(() => { this.onImageLoad() })
      .then(() => this.show(previousPage))
      .then(() => promise.wait(previousPage && !this.imagesToLoad ? 0 : 100))
      .then(() => {
        invokeMap(this.modulesArray, 'shown')
      })
  }

  preloadImages () {
    document.documentElement.classList.add('loading')
    if (!this.imagesToLoad || !this.imagesToLoad.length) return Promise.resolve()
    return Promise.all(map(this.imagesToLoad, this.loadImage))
  }

  loadImage = _img => new Promise(resolve => {
    const img = new Image()
    img.src = _img.src
    if (img.naturalWidth !== 0) return resolve()
    img.onload = () => {
      img.onload = null
      resolve()
    }
  })

  onImageLoad (callback, previousPage) {
    document.documentElement.classList.remove('loading')
    this.createModules()
  }

  createModules () {
    this.modulesArray = []
    this.modules = {}
    if (!this.moduleMap) return

    each(this.moduleMap, ([selector, Module], key) => {
      const array = map(
        this.el.querySelectorAll(selector),
        el => {
          const m = new Module(el, this.refs)
          return m
        }
      )
      if (array) this.modules[key] = array.length === 1 ? array[0] : array
      this.modulesArray.push(...array)
    })
  }

  flush () {
    invokeMap(this.modulesArray, 'flush')
    this.modulesArray = null
    super.flush()
  }

  resize () {
    invokeMap(this.modulesArray, 'resize')
    super.resize()
  }
}
