import 'normalize.css/normalize.css'
import 'styles/main.scss'

import Main from 'sections/Main'
import 'helpers/detect'

window.onload = () => new Main(document.body)

if (module.hot) module.hot.accept()

const fetch = window.fetch
