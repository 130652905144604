// import anime from 'animejs'

import scroll from 'core/scroll'
// import resize from 'helpers/resize'
import detect from 'helpers/detect'
// import math from 'helpers/math'

import Menu from '../menu/Menu'

export default class Header {
  constructor (el) {
    this.el = el
    this.prefix = this.el.className

    const menuEl = el.parentNode.querySelector('.menu')
    if (menuEl) this.menu = new Menu(menuEl)
    this.circles = Array.from(this.el.querySelectorAll('svg .circle'))
    this.circles.push(...Array.from(this.el.parentNode.querySelectorAll('.footer svg .circle')))
    scroll.instance().on(this.onScroll)

    this.button = this.el.querySelector('.header__menu-button')
    this.button && this.button.addEventListener('click', this.toggle)

    // this.toggle()
  }

  show () {
    // return anime({
    //   duration: 800,
    //   delay: (el, i, l) => i * 100,
    //   easing: 'easeOutQuad',
    //   targets: links,
    //   opacity: [0, 1]
    // }).finished
  }

  barVisible = false;

  scrollTo = (event) => {
    const { currentTarget } = event
    event.preventDefault()
    const id = currentTarget.getAttribute('href')
    const target = document.body.querySelector(id)
    // scroll.scrollTo(target.offsetTop, 600)
    scroll.scrollTo(target.offsetTop - this.el.offsetHeight, 600)
  }

  toggle = () => {
    if (this.menu) {
      if (this.menu.opened) this.menu.close()
      else this.menu.open()
    }
    document.body.classList.toggle('menu-opened', this.menu.opened)
  };

  onScroll = () => {
    const { scrollTop } = scroll
    const needBarVisible = scrollTop() > (detect.desktop ? 50 : 50)

    if (needBarVisible !== this.barVisible) {
      document.body.classList.toggle('collapsed', needBarVisible)
      this.barVisible = needBarVisible
    }

    const speed = [.6, .8, -1]
    const multiplier = .1
    this.circles.forEach((c, i) => {
      c.style.transform = `rotate(${(scrollTop() * speed[i % 3] * multiplier) % 360}deg)`
    })
  };

  flush () {
    this.button && this.button.removeEventListener('click', this.toggle)
    this.button = null

    this.scroll && this.scroll.forEach(s => s.removeEventListener('click', this.scrollTo))
    this.scroll = null

    this.menu && this.menu.flush()
    this.menu = null
    scroll.instance().off(this.onScroll)
  }

  resize () {}
}
