
import Menu from 'modules/menu/Menu'

export default class Filters extends Menu {
  getElements () {
    this.background = this.el.querySelector('.projects__filters-background')
    this.links = Array.from(this.el.querySelectorAll('.projects__filter, .projects__filters-list svg'))
    this.others = this.el.querySelector('.projects__filters-close')
  }
}
