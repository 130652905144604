import { isArray } from 'lodash'

class Page {
  state = { shown: false, hidden: false }

  constructor (el) {
    this.el = el
    this.bindRefs()
  }

  bindRefs () {
    this.refs = Array.from(this.el.querySelectorAll('*[data-ref]')).reduce((memo, el) => {
      const key = el.getAttribute('data-ref')
      if (key) {
        if (memo[key]) {
          if (!isArray(memo[key])) memo[key] = [memo[key]]
          memo[key].push(el)
        } else { memo[key] = el }
      }
      return memo
    }, {})
  }

  pageName () { return this.constructor.pageName || this.constructor.name || true }

  prepare (previousPage) {
    this.el.style.opacity = 0
  }

  askShow = previousPage => {
    return Promise.resolve()
      .then(() => this.show(previousPage))
      .then(() => { this.state.shown = true })
  }

  show (previousPage) {
    this.el.style.opacity = 1
  }

  askHide = nextPage => {
    return Promise.resolve()
      .then(() => this.hide(nextPage))
      .then(() => { this.state.hidden = true })
  }

  hide (nextPage) {
    this.el.style.opacity = 0
  }

  flush () {}

  resize () {}
}

export default Page
