import Filters from 'modules/filters/Filters'
import SuperPage from 'navigation/SuperPage'

export default class Projects extends SuperPage {
  constructor (el, options) {
    super(...arguments, Object.assign(options, {
      moduleMap: Object.assign(options.moduleMap, {
        filters: ['.projects__filters', Filters]
      })
    }))

    this.toggleEvents()
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.filtersButton[method]('click', this.togglePopin)
    this.refs.filtersClose[method]('click', this.togglePopin)
    this.refs.filterButton.forEach(b => {
      b[method]('mouseover', this.mouseover)
      b[method]('click', this.togglePopin)
    })
  }

  togglePopin = () => {
    if (this.modules.filters.opened) this.modules.filters.close()
    else this.modules.filters.open()

    setTimeout(() => {
      this.el.classList.toggle('show-filters', this.modules.filters.opened)
    }, 10)
  }

  mouseover = (e) => {
    const t = e.currentTarget
    const top = t.parentNode.offsetTop
    this.refs.filtersPopin.style.setProperty('--top', top + 'px')
  }

  flush () {
    this.toggleEvents(false)
    super.flush()
  }
}
